<script lang="ts">
  import { tweened } from 'svelte/motion';
  /** What value are we tweening to? */
  export let value: number | undefined = 0;
  /** How long should tweening take, in ms? */
  export let duration: number = 1000;
  /** How many decimal places? */
  export let fixed: number = 0;
  let progress = tweened(0, { duration });

  $: progress.set(value || 0);

  // onMount(() => {
  //   progress = tweened(0, { duration });
  //   progress.set(value || 0);
  // });
</script>

{$progress.toFixed(fixed)}
